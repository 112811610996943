<template>
  <section>
    <el-form :inline="true" class="toolbar" :model="searchForm">
      <el-form-item label="是否启用">
        <el-select clearable v-model="searchForm.deleted" placeholder="请选择">
          <el-option
            v-for="item in option"
            :key="item.num"
            :label="item.name"
            :value="item.num"
            @keyup.enter.native="search"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="searchButton" type="primary" size="mini"
          >查询</el-button
        >
        <el-button @click="addButton" type="primary" size="mini"
          >添加</el-button
        >
      </el-form-item>
    </el-form>
    <el-dialog
      title="添加/编辑"
      width="80%"
      :close-on-click-modal="false"
      :visible.sync="addDialogForm"
    >
      <el-form :model="addForm" ref="addForm" label-width="100px">
        <el-form-item v-if="false" prop="id"></el-form-item>
        <el-form-item label="下限(含)" prop="minAmt">
          <el-col :span="6">
            <el-input v-model="addForm.minAmt" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="上限(不含)" prop="maxAmt">
          <el-col :span="6">
            <el-input v-model="addForm.maxAmt" clearable></el-input>
          </el-col>
        </el-form-item>
        <div>
          <!-- 展示已有的行 -->
          <table>
            <tr>
              <td>渠道</td>
              <td>权重</td>
            </tr>
            <tr v-for="(item, index) in addForm.detailList" :key="index">
              <td>
                <el-select clearable v-model="item.payAccountId">
                  <el-option
                    v-for="xx in addForm.accountList"
                    :key="xx.id"
                    :label="xx.name"
                    :value="xx.id"
                  />
                </el-select>
              </td>
              <td>
                <el-input
                  class="input-box"
                  v-model="item.weight"
                  clearable
                ></el-input>
              </td>
              <td v-show="index != addForm.detailList.length - 1">
                <el-button type="danger" plain @click.prevent="removeRow(index)"
                  >删除</el-button
                >
              </td>
              <td v-show="index == addForm.detailList.length - 1">
                <el-button
                  type="primary"
                  size="medium"
                  @click.prevent="addNewRow"
                  >添加</el-button
                >
              </td>
            </tr>
          </table>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelButton">取消</el-button>
        <el-button type="primary" @click="saveButton" :loading="addLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-table
      :data="tableData"
      border
      height="650"
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column
        align="center"
        prop="deleted"
        label="是否启用"
        width="auto"
        :min-width="80"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.deleted"
            :active-value="0"
            :inactive-value="1"
            active-color="#13ce66"
            inactive-color="#e6e6e6"
            @change="handleRouterSwitch(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="minAmt"
        label="下限(含)"
        width="auto"
        :min-width="120"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="maxAmt"
        label="上限(不含)"
        width="auto"
        :min-width="120"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="router"
        label="支持通道"
        width="auto"
        :min-width="420"
      ></el-table-column>
      <el-table-column
        align="center"
        label="操作"
        width="auto"
        :min-width="120"
      >
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="rowEdit(scope.row)"
            >配置</el-button
          >
          <el-button
            type="primary"
            size="mini"
            v-show="scope.row.deleted == 1"
            @click="rowDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>
  </section>
</template>

<script>
import api from "../../api/merchantPayConfig/merchantPayOut";

export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 100,
      dataTotal: 0,
      loading: false,
      addDialogForm: false,
      tableData: [],
      searchForm: {
        deleted: 0,
      },
      addForm: {
        id: "",
        minAmt: "",
        maxAmt: "",
        detailList: [],
        accountList: this.accountSelect(),
      },
      option: [
        { num: 0, name: "启用" },
        { num: 1, name: "关闭" },
        { num: "", name: "全部" },
      ],
      sequence: [],
      addLoading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      api
        .routerSelect({
          content: { deleted: this.searchForm.deleted, type: 0 },
          type: 0,
        })
        .then((res) => {
          if (res.code === "0000") {
            this.loading = false;
            this.tableData = res.data;
            this.tableData.forEach((item) => {
              this.sequence.push(item.orderValue);
            });
          }
        });
    },
    handleRouterSwitch(row) {
      api
        .routerSwitch({
          content: { deleted: row.deleted, id: row.id },
        })
        .then((res) => {
          if (res.code === "0000") {
            this.$message.success("操作成功！");
            this.getList();
          } else {
            this.$message.error(row.message);
          }
        });
    },
    accountSelect() {
      api
        .routerChannel({
          type: 0,
        })
        .then((res) => {
          if (res.code == "0000") {
            this.addForm.accountList = res.data;
          }
        });
    },
    setInput(row) {
      if (row.orderValue < 0) {
        row.orderValue = 0;
      }
    },
    setInputMin(row) {
      if (row.minAmt < 0 || row.minAmt == "") {
        row.minAmt = 0;
      }
      if (row.minAmt >= row.maxAmt && row.maxAmt !== 0) {
        row.minAmt = row.maxAmt - 1;
        this.$message.error("最小值需小于最大值");
      }
    },
    setInputMax(row) {
      if (row.maxAmt < 0) {
        row.maxAmt = 0;
      }
      if (row.maxAmt <= row.minAmt) {
        row.maxAmt = row.minAmt + 1;
        this.$message.error("最大值需大于最小值");
      }
    },

    searchButton() {
      this.pageIndex = 1;
      this.getList();
    },
    addButton() {
      this.$refs.addForm && this.$refs.addForm.resetFields();
      this.addDialogForm = true;
      this.addForm.id = 0;
      this.addForm.minAmt = "";
      this.addForm.maxAmt = "";
      this.addForm.detailList = [{ payAccountId: "", weight: "" }];
    },
    cancelButton() {
      this.addDialogForm = false;
      this.addForm.detailList = [{ payAccountId: "", weight: "" }];
    },
    rowEdit(row) {
      this.addForm.id = row.id;
      this.addForm.minAmt = row.minAmt;
      this.addForm.maxAmt = row.maxAmt;
      this.accountSelect();
      this.getRouterDetail(row);
      this.addDialogForm = true;
    },
    rowDel(row) {
      api
        .routerDelete({
          content: { id: row.id },
        })
        .then((res) => {
          if (res.code === "0000") {
            this.$message.success("操作成功！");
            this.getList();
          } else {
            this.$message.error(row.message);
          }
        });
    },
    getRouterDetail(row) {
      api
        .routerDetail({
          content: { id: row.id },
        })
        .then((res) => {
          if (res.code === "0000") {
            if (res.data.length == 0) {
              this.addForm.detailList = [{ payAccountId: "", weight: "" }];
            } else {
              this.addForm.detailList = res.data;
            }
          } else {
            this.$message.error(row.message);
          }
        });
    },
    saveButton() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.addLoading = true;
          let _data = {};
          let content = Object.assign({}, this.addForm);
          content.type = 1;
          _data.content = content;
          api.routerEdit(_data).then((res) => {
            this.addLoading = false;
            if (res.code == "0000") {
              this.addDialogForm = false;
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.searchButton();
              // this.bindGrid();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
    },
    pageIndexChange(val) {
      this.pageIndex = val;
    },
    addNewRow() {
      this.addForm.detailList.push({ payAccountId: "", weight: "" });
    },
    removeRow(index) {
      this.addForm.detailList.splice(index, 1);
    },
  },
  components: {},
};
</script>

<style lang="scss">
.el-checkbox__inner {
  width: 20px;
  height: 20px;
}
.el-checkbox__inner::after {
  height: 10px;
  left: 6px;
}
.el-input__inner {
  text-align: center;
}
</style>
