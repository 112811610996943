import request from "@/utils/http/requset";

export function routerSwitch(data) {
    return request({
        url: "/manage/merchant/router/switch",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}

export function routerEdit(data) {
    return request({
        url: "/manage/merchant/router/edit",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}

export function routerSelect(data) {
    return request({
        url: "/manage/merchant/router/select",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}

export function routerChannel(data) {
    return request({
        url: "/manage/merchant/router/channel",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}

export function routerDetail(data) {
    return request({
        url: "/manage/merchant/router/detail",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}

export function routerDelete(data) {
    return request({
        url: "/manage/merchant/router/delete",
        method: "post",
        ContentType: 'application/json;charset=UTF-8',
        data: data
    });
}


export default {
    routerSwitch,
    routerEdit,
    routerSelect,
    routerChannel,
    routerDetail,
    routerDelete
};
